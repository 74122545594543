@import "./abstracts/variables";

/* @font-face {
  font-family: "Carton Six";
  src: url("../../assets/fonts/Carton_Six.ttf");
}

@font-face {
  font-family: "Univers";
  src: url("../../assets/fonts/UniversRegular.ttf");
}
@font-face {
  font-family: "Univers";
  src: url("../../assets/fonts/UniversLight.ttf");
  font-weight: 300;
} */


 
// Create your own map
$custom-colors: (
  "white": #ffffff,
  "black": #000000,
  "custom-color": rgb(83, 141, 85),
  "kidoki-pink": #EFA0BE,
  "kidoki-grey": #e5e8e6,
  "kidoki-yellow": #F9DF65,
  "kidoki-green": #5CAE6D,
  "kidoki-navy": #00293d,
  "kobe-light-brown": #C3A271,
  "kobe-dark-brown": #4C2F22,
  "kobe-off-white": #EDEBDC,
  "hb-brown": #BD9673,
  "hb-green": #115E33,

  "critter-grey": #e6e8e6,
  "critter-green": #82b949,
  "critter-modal-grey": #E6E8E7
);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

@import "~bootstrap/scss/bootstrap";