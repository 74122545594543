/* crafters */
/* @media (max-width: 768px) { 

}
@media (max-width: 576px) { 

} */




.section-chico {
    h6 {
        font-weight: 300;
        font-size: 1.75rem;
    }

    h1 { 
        font-size: 4rem;

        @media (max-width: 576px) {
            font-size: 3rem;
        }
    }

    p { 
        font-size: 2rem; 
        
        @media (max-width: 576px) { 
            font-size: 1rem;
        }
    }

    .chico-sig { width: 36%; }
}



.section-forest {

   

    h2 {
        font-weight: 300;
        margin-bottom: 2rem;

        @media (max-width: 576px) {
            font-size: 1.2rem;
        }
    }

    a.learn-more-btn {
        background-color: white;
        color: black;
        text-decoration: none;
        padding: 0.6rem 2.3rem;
        border-radius: 50px;
        text-transform: uppercase;
        font-weight: 500;

        @media (max-width: 576px) {
            padding: 0.5rem 1rem;
        }
    }
}



.section-critters {

    

    .critter-img-container {

        cursor: pointer;

        .down {
            display: none;
        }

        &:hover {
            // .down {
            //     display: block;
            // }
            .up {
                display: none;
            }
            .down {
                display: block;
            }
        }
    }

}


 


/* product section */
.product-section {

    a {
        text-decoration: none;
    }

    .prod-container {

        margin-bottom: 2.75rem;
        padding: 0.25rem;

        
        >p{
            margin-bottom: 0;
            font-size: 12px;
            

            @media (min-width: 991.98px) {
                font-size: 18px;
            }
        }

        &:hover {
            outline: 8px solid #115E33;
            /* border-radius: 10px; */
        }
        
    }

    .collection-btn-container {
        display: inline-block;
        position: relative;


        a.view-collection-btn {
            font-family: "Univers";
            font-weight: bold;
            padding: 0.5rem 1.75rem 0.55rem;
            border-radius: 15px;
            font-size: 1.55rem;
        }

        img.btn-img-hover {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            transition: all 0.2s ease-in-out;

            &.btn-ladybug {
                width: 100px;
                right: -150px;
                transform: rotate(190deg);
            }
            &.btn-worm {
                width: 175px;
            }
            &.btn-sun {
                width: 60px;
                // right: -50px;
            }
            &.btn-leaf {
                left: -180px;
                width: 137px;
            }
            &.btn-flower {
                width: 80px;
                left: -175px;
            }
        }

        &:hover {
            img.btn-img-hover.btn-ladybug {
                transform: rotate(189deg) translate(-10px, -32px);
            }
            img.btn-img-hover.btn-worm {
                transform: translate(130px, 0);
            }
            img.btn-img-hover.btn-sun{
                width: 96px;
                // transform: translate(0, -30px);
                transform: translate(0, -31px);
            }
            img.btn-img-hover.btn-leaf {
                transform: translate(-45px, 30px);
            }
            img.btn-img-hover.btn-flower {
                transform: translate(-10px, -30px);
            }
        }


    }

}


/* insta container */
.insta-section  {
    .col-4.text-center {
        padding: 6px;
    }

    .insta-container {
        position:relative;

        span.insta-icon-hover {
            background-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity:0;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity 0.1s ease-in-out;

            img {
                width: 20px;
            }
        }

        &:hover span.insta-icon-hover {
            opacity: 1;
        }
    }

    padding-bottom: 2rem;
}


.critter-modal {
    position: fixed;
    /* top: 2rem;
    left: 2rem;
    right: 2rem;
    bottom: 2rem; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;

    .modal-year {
        // display: none;
    }

    .modal-title {
        font-size: 2.25rem;
    }

    .modal-description {
        font-size: 0.85rem;
        line-height: 1.4rem;
    }

    .modal-sub-image {
        cursor: pointer;
    }

    .modal-buy-btn-container {
        text-align: center;

        a.modal-buy-btn,
        a.modal-buy-btn-eu {
            background-color: #000;
            color: white;
            text-decoration: none;
            padding: 0.5rem 2.5rem;
            text-transform: uppercase;
            border-radius: 24px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:first-child {
                margin-bottom: 0.5rem;
            }

            &:hover {
                opacity: 0.9;
            }
        }
    }

    .modal-buy-image {
        width: 20px;
        margin-right: 8px;
    }

    .meet-more-selector {
        filter: drop-shadow(4px 4px 1px #00000040);
        transition: transform 0.1s ease-in-out;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }

    .meet-more-left-right {
        font-size: 5rem;
        font-weight: 100;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }


    }

    span.close-btn {
        background-color: black;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 46px;
        color: white;
        font-weight: 600;
        position: fixed;
        top: 50px;
        left: 50px;
        cursor: pointer;

        &:hover {
            opacity: 0.9;
        }

        @media (max-width: 576px) {
            top: 20px;
            left: 20px;
            width: 30px;
            height: 30px;

        }
    }
}